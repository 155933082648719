<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />

    <div class="apipe-cloud-course-container">
      <div class="top-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h2>新闻资讯</h2>
        </div>
      </div>
      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="">
          <h4>新闻资讯</h4> 
        </div>
      </div>

      <div class="pc-case container d-none d-lg-block">
        <div class="wrap">
          <div class="lf-opt">
            <div
              @click="handleSelectTpye(idxs)"
              v-for="(itm, idxs) in types"
              :key="idxs"
              :class="['opertaion', sel == idxs ? 'active' : '']"
            >
              <span>{{ itm }}</span>
              <span
                style="margin-left: 10px"
                class="el-icon-arrow-right"
              ></span>
            </div>
          </div>
          <div style="width: 100%">
            <div class="catcary">
              <div class="content" v-if="listcot.length">
                <div class="content-warp">
                  <div
                    class="itms"
                    v-for="(item, index) in listcot"
                    :key="index"
                    @click="handleDetail(item.id)"
                  >
                    <div class="pic-wrap">
                      <img
                       v-if="item.contentImgUrl"
                      class="main-pic"
                      :src="fixedPath + item.contentImgUrl"
                      alt=""
                    />
                    <img class="main-pic" v-else src="@/assets/newSite/news/no.png" alt="">
                    </div>
                    <div class="cot">
                      <div class="tit">
                        <span>{{ item.contentDatetime }}</span>
                        <img
                          class="like"
                          src="@/assets/newSite/news/like.png"
                          alt=""
                        />
                        <span class="nums">{{ item.num }}</span>
                        <img
                          class="eyes"
                          src="@/assets/newSite/news/eyes.png"
                          alt=""
                        />
                        <span class="look">{{ item.viewNum }}</span>
                      </div>
                      <h6 class="name">{{ item.contentTitle }}</h6>
                      <div class="des">
                         {{ item.contentDescription }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!listcot.length" style="display: flex;align-items: center;justify-content: center;height: 100%;">暂无数据...</div>
            <div class="pageNation container" v-if="listcot.length">
              <div class="preAndNext">
                <div
                  :class="['before', current == 1 ? 'dis' : 'activ']"
                  @click="lunboleft"
                >
                  <span class="el-icon-arrow-left"></span>
                </div>
                <div class="nums">{{ current }} / {{ maxPage }}</div>
                <div
                  :class="['after', current == maxPage ? 'dis' : 'activ']"
                  @click="lunboright"
                >
                  <span class="el-icon-arrow-right"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-case d-block d-lg-none">
        <div class="lf-opt">
          <div
            @click="handleSelectTpye(idxs)"
            v-for="(itm, idxs) in types"
            :key="idxs"
            :class="['opertaion', sel == idxs ? 'active' : '']"
          >
            <span>{{ itm }}</span>
          </div>
        </div>

        <div class="catcary">
          <div class="content" v-if="listcot.length">
            <div class="content-warp">
              <div class="itms" @click="handleDetail(item.id)" v-for="(item, index) in listcot" :key="index">
               <div>
                <img
                  v-if="item.contentImgUrl"
                  class="main-pic"
                  :src="fixedPath + item.contentImgUrl"
                  alt=""
                />
                <img class="main-pic" v-else src="@/assets/newSite/news/no.png" alt="">
               </div>
                <div class="cot">
                  <div class="tit">
                    <div>
                      <span>{{ item.createDate }}</span>
                    </div>
                    <div>
                      <img
                        class="like"
                        src="@/assets/newSite/news/like.png"
                        alt=""
                      />
                      <span class="nums">{{ item.num }}</span>
                      <img
                        class="eyes"
                        src="@/assets/newSite/news/eyes.png"
                        alt=""
                      />
                      <span class="look">{{ item.viewNum }}</span>
                    </div>
                  </div>
                  <h6 class="name">{{ item.contentTitle }}</h6>
                  <div class="des">
                    {{ item.contentDescription }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else style="display: flex;align-items: center;justify-content: center;height: 100%;padding: 20px 0;">暂无数据...</div>
        </div>

        <div class="pageNation container" v-if="listcot.length">
          <div class="preAndNext">
            <div
              :class="['before', current == 1 ? 'dis' : 'activ']"
              @click="lunboleft"
            >
              <span class="el-icon-arrow-left"></span>
            </div>
            <div class="nums">{{ current }} / {{ maxPage }}</div>
            <div
              :class="['after', current == maxPage ? 'dis' : 'activ']"
              @click="lunboright"
            >
              <span class="el-icon-arrow-right"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NewFooter />
    <MobileFooter class="d-block d-lg-none" />
  </div>
</template>
        
  <script>
import { Newslist } from "@/api/fetch"; 
import Qs from "qs";
export default {
  name: "hardware",
  data() {
    return {
      cur: 1,
      fixedPath:this.$BaseUrl,
      activeName: "1",
      idx: 0,
      ids: -1,
      listcot: [],
      total: 0,
      current: 1,
      maxPage: 1,
      pages: {
        pageSize: "10",
        pageNo: 1,
        categoryId:'1704670299637411842'  //公司动态  1704670418562707457 行业资讯
      },
      seach: {
        indus: "全部",
        pro: "全部",
      },
      sel: "",
      showIndus: false,
      showPro: false,
      types: ["公司动态", "行业资讯"],
    };
  },
  created() {
    this.handleNews();
  },
  methods: {
    handleDetail(id){
        this.$router.push({
            path:'/detail',
            query:{
              id,
            }
        }) 
    },
    handleSelectTpye(val) {
      this.sel = val;
      if(val == 0){
        this.pages.categoryId ='1704670299637411842'
      }else{
        this.pages.categoryId ='1704670418562707457'
      }
      this.handleNews();
    },
    lunboleft() {
      if (this.current == 1) return;
      this.current = this.current - 1;
      this.pages.pageNo = this.current;
      this.handleNews();
    },
    lunboright() {
      if (this.current == this.maxPage) return;
      this.current = this.current + 1;
      this.pages.pageNo = this.current;
      this.handleNews();
    },

    handleNews() {
      const prams = this.pages;
      Newslist(Qs.stringify(prams)).then((res) => {
        this.total = res.data.total;
        this.listcot = res.data.rows || [];
        this.maxPage = Math.ceil(this.total / this.pages.pageSize);
      }).catch(e=>{
        this.listcot =  [];
        this.total = 0;
        this.maxPage = 0;
      });
    },
    handlePre(e) {
      console.log(e);
      this.pages.pageNo = e;
      this.handleNews();
    },
    handleNext(e) {
      console.log(e);
      this.pages.pageNo = e;
      this.handleNews();
    },
  },
};
</script>
        
        <style lang="less"  scoped>
.apipe-cloud-course-container {
  .top-banner {
    height: 600px;
    background-image: url("../../assets/newSite/news/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 16px;
      }
    }
  }
  .mobile-banner {
    height: 200px;
    background-image: url("../../assets/newSite/home/p1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 12px;
      }
      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .pc-case {
    margin-top: 30px;
    margin-bottom: 30px;
    .wrap {
      display: flex;
    }
    .lf-opt {
      width: 200px;
      margin: 10px 10px 0 10px;
      .opertaion {
        height: 70px;
        line-height: 70px;
        cursor: pointer;
        background: white;
        font-size: 1rem;
        font-family: "思源黑体";
        position: relative;
      }
      .active {
        background: rgba(224, 239, 255, 1);
        color: rgba(20, 132, 255, 1);
        position: relative;
        overflow: hidden;
        :after {
          position: absolute;
          content: "";
          left: 0;
          height: 70px;
          width: 3px;
          background: rgba(20, 132, 255, 1);
        }
      }
    }
    .pro-tit {
      text-align: left;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 10px;
    }
    .catcary {
      text-align: left;
      .content {
        min-height: 600px;
        margin-bottom: 30px;
        .content-warp {
          .itms {
            width: 100%;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
            margin: 10px 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            .pic-wrap{
              // border:1px solid red; 
              padding: 10px;
             overflow: hidden;
              width: 200px;
              height: 130px;
              display: flex;
              align-items: center;
              justify-content: center;
              .main-pic {
              width: 150px;
              height: auto;
            }
            }

            .cot {
              padding: 20px;
              width: 100%;
              .des {
                font-size: 14px;
                color: #666666;
                margin: 6px 0;
              }
              .tit {
                font-size: 14px;
                color: #333333;
                display: flex;
                align-items: center;
                margin-bottom: 6px;
                .like,
                .eyes {
                  width: 16px !important;
                  height: 16px !important;
                  margin: 0 10px 2px 16px;
                }
                .nums,
                .look {
                  color: #999999;
                }
              }
              .name {
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    .pageNation {
      padding: 10px 0;
      .preAndNext {
        display: flex;
        align-items: center;
        .nums {
          margin: 0 10px;
        }
        .before,
        .after {
          display: inline-block;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid #ccc;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
        }
        .dis {
          // border: 1px solid #ccc;
          cursor: not-allowed !important;
        }
        .activ {
          border: 1px solid rgba(20, 132, 255, 1);
        }
      }
    }
  }
  .mobile-case {
    // margin-top: 30px;
    .wrap {
      display: flex;
    }
    .lf-opt {
      width: 100%;
      //   margin: 10px 10px 0 10px;
      display: flex;
      .opertaion {
        flex: 1;
        height: 70px;
        line-height: 70px;
        cursor: pointer;
        background: white;
        font-size: 1rem;
        font-family: "思源黑体";
        position: relative;
      }
      .active {
        background: rgba(224, 239, 255, 1);
        color: rgba(20, 132, 255, 1);
      }
    }
    .pro-tit {
      text-align: left;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 10px;
    }
    .catcary {
      text-align: left;
      .content {
        min-height: 600px;
        margin-bottom: 30px;
        .content-warp {
          padding: 10px;
          .itms {
            width: 100%;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
            margin-bottom: 10px;
            box-sizing: border-box;
            .main-pic {
              width: 100%;
              height: auto;
            }
            .cot {
              padding: 20px;
              .des {
                font-size: 14px;
                color: #666666;
                margin: 6px 0;
              }
              .tit {
                font-size: 14px;
                color: #333333;
                display: flex;
                align-items: center;
                margin-bottom: 6px;
                justify-content: space-between;
                .like,
                .eyes {
                  width: 16px !important;
                  height: 16px !important;
                  margin: 0 10px 2px 16px;
                }
                .nums,
                .look {
                  color: #999999;
                }
              }
              .name {
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    .pageNation {
      padding: 10px 0;
      .preAndNext {
        display: flex;
        align-items: center;
        justify-content: center;
        .nums {
          margin: 0 10px;
        }
        .before,
        .after {
          display: inline-block;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid #ccc;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
        }
        .dis {
          cursor: not-allowed !important;
        }
        .activ {
          border: 1px solid rgba(20, 132, 255, 1);
        }
      }
    }
  }
}
</style>
        